jQuery(document).ready(function ($) {
	console.log("currency switcher");
	// default currencySymbol value £
	var currencySymbol = "£";
	$("#input_25_94").change(function () {
		var selectedCurrency = $(this).val();

		// console.log("selectedCurrency", selectedCurrency);

		switch (selectedCurrency) {
			case "USD":
				currencySymbol = "$";
				break;
			case "EUR":
				currencySymbol = "€";
				break;
			case "GBP":
				currencySymbol = "£";
				break;
			default:
				currencySymbol = "$";
		}

		// Update all currency symbols
		updateCurrencySymbols(currencySymbol);

		function updateCurrencySymbols(currencySymbol) {
			// field ids:
			// input_25_67
			// input_25_70
			// input_25_71
			// input_25_72
			// sum field: input_25_74

			// List of field IDs to update
			var fieldIds = [
				"input_25_67",
				"input_25_70",
				"input_25_71",
				"input_25_72",
				"input_25_74",
			];

			fieldIds.forEach(function (fieldId) {
				var field = $("#" + fieldId);
				var fieldValue = field.val();

				if (fieldValue !== "") {
					// Remove the old currency symbol
					fieldValue = fieldValue.replace(/^[^\d]+/, "");

					// Add the new currency symbol
					field.val(currencySymbol + fieldValue);
				}
			});
		}
	});

	$(".currency_input input").on({
		input: function () {
			formatCurrency($(this));
		},
		blur: function () {
			formatCurrency($(this), "blur");
		},
	});

	function cleanNumber(value) {
		// Remove all non-numeric characters except for decimal points and commas
		return value
			.replace(/[^0-9.,]/g, "") // Remove all non-numeric characters except . and ,
			.replace(/,/g, ""); // Remove thousands separators
	}

	function formatNumber(n) {
		return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	function formatCurrency(input, blur) {
		var input_val = input.val();

		if (input_val === "") {
			return;
		}

		var original_len = input_val.length;
		var caret_pos = input.prop("selectionStart");

		if (input_val.indexOf(".") >= 0) {
			var decimal_pos = input_val.indexOf(".");
			var left_side = input_val.substring(0, decimal_pos);
			var right_side = input_val.substring(decimal_pos);

			left_side = formatNumber(left_side);
			right_side = formatNumber(right_side);

			if (blur === "blur") {
				right_side += "00";
			}

			right_side = right_side.substring(0, 2);

			input_val = currencySymbol + left_side + "." + right_side;
		} else {
			input_val = formatNumber(input_val);
			input_val = currencySymbol + input_val;

			if (blur === "blur") {
				input_val += ".00";
			}
		}

		input.val(input_val);

		var updated_len = input_val.length;
		caret_pos = updated_len - original_len + caret_pos;
		input[0].setSelectionRange(caret_pos, caret_pos);
	}

	function sumUp() {
		// Extract and clean values from input_25_70 and input_25_72
		var val1 = parseFloat(cleanNumber($("#input_25_70").val())) || 0;
		var val2 = parseFloat(cleanNumber($("#input_25_72").val())) || 0;

		// Calculate the sum
		var sum = val1 + val2;

		// Update the sum field (input_25_74)
		// $("#input_25_74").val(currencySymbol + formatNumber(sum.toFixed(2)));

		$("#input_25_74").val(
			currencySymbol + formatNumberWithCommas(sum.toFixed(2))
		);
	}

	// Attach the sumUp function to input changes on input_25_70 and input_25_72
	$("#input_25_70, #input_25_72").on("input", function () {
		sumUp();
	});

	// Call sumUp on page load to ensure correct initial value
	sumUp();

	function formatNumberWithCommas(number) {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
});
